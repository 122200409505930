import axios from 'axios'
import showToastify from '../utils/toastify'
import "toastify-js/src/toastify.css"
import { find } from 'utils/dom'

export default function contatoForm() {
    const page = find("[js-page='contato']");
    if (!page) return;

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector('.contact-form-2 button[type=submit]')

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add('disabled')
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove('disabled')
    }


    const contatoFormSubmit = document.querySelector('.contact-form-2');

    contatoFormSubmit &&
        contatoFormSubmit.addEventListener('submit', function (event) {
            event.preventDefault()
            disabledBtn()

            const formData = new FormData()
            formData.append('nome', document.querySelector('.contact-form-2 .form-group input[name=nome]').value)
            formData.append('email', document.querySelector('.contact-form-2 .form-group input[name=email]').value)
            formData.append('telefone', document.querySelector('.contact-form-2 .form-group input[name=telefone]').value)
            formData.append('mensagem', document.querySelector('.contact-form-2 .form-group textarea[name=mensagem]').value)
            formData.append('curriculo', document.querySelector('.contact-form-2 .form-group input[name=curriculo]').files[0])

            const url = '/trabalhe-conosco/'
            axios
                .post(url, formData)
                .then((response) => showMessage(response))
                .catch((response) => showMessage(response))
        })


    const fileImage = document.querySelector('.contact-form-2 input[type="file"]');
    const fileInput = document.querySelector('.contact-form-2 input[name=curriculo]')

    function validateFile() {
        const alertPdf = document.querySelector('[data-alert-pdf]')
        const file = fileInput.files[0];
        if (file && file.type !== 'application/pdf') {
            alertPdf.classList.remove('hidden')
            fileInput.value = ''; // Limpa o campo
            fileInput.files[0] = '';
        }
        else alertPdf.classList.add('hidden')
    }

    fileImage.addEventListener('change', (e) => {
        console.log(e.target);
        validateFile()
    })
}