import { find, findAll } from "../utils/dom";


const Quadro_Rentabilidade = () => {

	const page = find(`[js-page="quadro-rentabilidade"]`);
	if (!page) return;

	const BASEURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`


	function splitFilters(filtros) {
		if (!filtros) return;
		const splitFiltros = filtros.replace("?", "").split("&");
		const filtrosObj = {};
		splitFiltros.forEach((filtro) => {
			const splitFiltro = filtro.split("=");
			filtrosObj[splitFiltro[0]] = splitFiltro[1];
		});
		return filtrosObj;
	}

	function generateStringFilterFromObject(obj) {
		const filtros = Object.entries(obj).map(
			([key, value]) => `&${key.trim()}=${value.trim()}`
		);

		const string = filtros.join("");

		return `?${string.slice(1)}`;
	}


	function handleSubmit(form) {
		const submits = findAll("[js-submit]");
		if (!submits) return;




		submits.forEach((item) => {
			item.addEventListener("input", (e) => {
				form.dispatchEvent(new Event("submit", { cancelable: true }));
			})
		})
	}

	function handleFormFiltro(form) {
		if (!form) return;

		form.addEventListener("submit", (e) => {
			e.preventDefault();

			const dados_form = new FormData(form);
			const filtroURL = splitFilters(window.location.search);

			if (filtroURL) {

				if (dados_form.get('fundo')) {
					filtroURL.fundo = dados_form.get('fundo')
				}

				if (dados_form.get('data') || dados_form.get('data') == '') {
					filtroURL.data = dados_form.get('data')
				}


				const String = generateStringFilterFromObject(filtroURL);

				window.location.replace(`${BASEURL}${String}`);


			} else {
				const novoObj = {
					fundo: dados_form.get('fundo') || "",
					data: dados_form.get('data') || ""
				}


				const String = generateStringFilterFromObject(novoObj);

				window.location.replace(`${BASEURL}${String}`);
			}

		})

	}

	/** Adicionando os valores passados nos parâmetros nos repectivos inputs após carregar a página. */
	function handleSetValues(form) {
		const obj = splitFilters(window.location.search);
		if (!obj) return;

		Object.entries(obj).forEach(([key, value]) => {
			const input = form.querySelector(`[name=${key}]`);
			input.value = value;
		})
	}


	const form = find('#form-filro');
	handleFormFiltro(form);
	handleSubmit(form);
	handleSetValues(form);

}

export default Quadro_Rentabilidade;