import { findAll } from "../utils/dom";

const Tabela_Performance = () => {

    function ordena_tabela_ano(a, b) {
        const infoA = a.getAttribute('js-ano-performance');
        const infoB = b.getAttribute('js-ano-performance');

        if (infoA < infoB) return -1;
        if (infoA > infoB) return 1;
        return 0;
    }

    const tabelas = findAll('[js-body-tablet]');
    if (!tabelas) return;

    tabelas.forEach((tabela_single) => {
        const Ano_Tabela = tabela_single.querySelectorAll('[js-ano-performance]');


        [...Ano_Tabela].sort(ordena_tabela_ano);

        let ano_inicial = 0;

        Ano_Tabela.forEach((tabela) => {
            const ano_table = parseInt(tabela.getAttribute('js-ano-performance'));
            if (ano_inicial != ano_table) {
                const total_ano_table = tabela_single.querySelectorAll(`[js-ano-performance='${ano_table}']`).length;
                tabela.setAttribute('rowspan', total_ano_table);

								const titulo = tabela.nextElementSibling;
                const titulo_texto = titulo.innerText;
                titulo.innerHTML = `
                    <span style="font-size: 10pt;">
                        ${titulo_texto}
                    </span>
                `
            }
            else {
                tabela.style.display = "none";
            }

            ano_inicial = ano_table;
        })
    })

}

export default Tabela_Performance;