import Swiper from "swiper"
import { find } from "utils/dom"
import axios from 'axios'
import showToastify from '../utils/toastify'
import "toastify-js/src/toastify.css"
import { findAll } from "../utils/dom"

export default function () {
	const Home = find("[js-page='invista_aqui']")
	if (!Home) return

	if (window.innerWidth <= 1024) {
		const swiperParceiroInvista = new Swiper(".swiper-parceiros", {
			spaceBetween: 8,
			centeredSlides: true,
			loop: true,
			autoplay: {
				delay: 4500,
				disableOnInteraction: false,
			}
		});
	}

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector('.contact-form-invista button[type=submit]')

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add('disabled')
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove('disabled')
	}

	const contatoFormSubmit = document.querySelector('.contact-form-invista');

	contatoFormSubmit &&
		contatoFormSubmit.addEventListener('submit', function (event) {
			event.preventDefault()
			disabledBtn()

			const formData = new FormData()
			formData.append('nome', document.querySelector('.contact-form-invista .form-group input[name=nome]').value)
			formData.append('email', document.querySelector('.contact-form-invista .form-group input[name=email]').value)
			formData.append('telefone', document.querySelector('.contact-form-invista .form-group input[name=telefone]').value);

			const url = '/invista-aqui-email/'
			axios
				.post(url, formData)
				.then((response) => showMessage(response))
				.catch((response) => showMessage(response))
		})

}