import { findAll } from "../utils/dom";

const EditorJS = () => {

	const EditorSimples = findAll("[js-editor-simples]");

	/** Para adicionar texto no front de maneira simples */
	if (EditorSimples) {
		EditorSimples.forEach((editor) => {
			if (editor.innerText) {
				let Texto = JSON.parse(editor.innerText);
				let ResultText = '';
				let TotalParagraph = Texto.blocks.length

				for (let i = 0; i < TotalParagraph; i++) {
					if (TotalParagraph > 0) {
						ResultText += `${Texto.blocks[i].data.text} <br />`;
					}
					else {
						ResultText = `${Texto.blocks[i].data.text}`;
					}


				}

				editor.innerHTML = ResultText;
			}
			//editor.innerHTML = Texto.blocks[0].data.text;
		})
	}

	const EditorTags = findAll("[js-editor-tags]");


	if (EditorTags) {
		EditorTags.forEach((editor) => {
			if (editor.innerText) {
				let Texto = JSON.parse(editor.innerText);
				let ResultText = '';
				let TotalTag = Texto.blocks.length;

				for (let i = 0; i < TotalTag; i++) {
					const Tipo = Texto.blocks[i].type;
					if (Tipo == 'paragraph') {
						ResultText += `<p>${Texto.blocks[i].data.text}</p>`
					}
					if (Tipo == 'Header') {
						const Level = Texto.blocks[i].data.level
						ResultText += `<h${Level}>${Texto.blocks[i].data.text}</h${Level}>`;
					}

					if (Tipo == 'List') {
						ResultText += `<ul>`
						const Itens = Texto.blocks[i].data.items;

						for (let k of Itens) {
							ResultText += `<li>${k}</li>`
						}
						ResultText += `</ul>`
					}

				}

				editor.innerHTML = ResultText;
			}
		})
	}


}

export default EditorJS;